<template>
  <div style="height: 100%;">
    <head-layout head-title="置顶文件维护"></head-layout>
    <div class="content">
      <div class="content-item">
        <head-layout :showIcon="false" head-title="全部列表"></head-layout>
        <div class="head-search">
          <el-input
            placeholder="请输入搜索内容"
            suffix-icon="el-icon-search"
            clearable
            v-model="searchLeft"
            @input="search(pageLeft)"
          >
          </el-input>
        </div>

        <grid-layout
          ref="gridLayOutLeft"
          :table-options="tableOptionsLeft"
          :table-data="tableDataLeft"
          :table-loading="crudLoadingLeft"
          :data-total="pageLeft.total"
          :page="pageLeft"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionLeftChange"
        ></grid-layout>
      </div>
     <div class="tree-transfer-middle">
       <el-button
        style="margin-right: 5px;"
         circle
         type="primary"
         icon="el-icon-arrow-left"
         @click="checkedChange('left')"
       ></el-button>
       <el-button
         circle
         type="primary"
         icon="el-icon-arrow-right"
         @click="checkedChange('right')"
       ></el-button>
     </div>
      <div class="content-item">
        <head-layout :showIcon="false" head-title="已选置顶"></head-layout>
        <div class="head-search">
          <el-input
            placeholder="请输入搜索内容"
            suffix-icon="el-icon-search"
            clearable
            v-model="searchRight"
            @input="searchR(pageRight)"
          >
          </el-input>
        </div>
        <grid-layout
          ref="gridLayOutRight"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptionsRight"
          :table-data="tableDataRight"
          :table-loading="crudLoadingRight"
          :data-total="pageRight.total"
          :page="pageRight"
          @page-current-change="onLoadRight"
          @page-size-change="onLoadRight"
          @page-refresh-change="onLoadRight"
          @row-cancel="rowCancel"
          @gird-handle-select-click="selectionRightChange"
        ></grid-layout>
      </div>
    </div>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
  listWithType,
  pageList,
  updateIsTrendingSearch,
} from "@/api/knowledgeClassifcation/knowledge";
export default {
  name:'hotSearchManagement',
  data() {
    return {
      tableDataLeft: [],
      tableDataRight: [],
      crudLoadingLeft: false,
      crudLoadingRight: false,
      searchLeft: "",
      searchRight: "",
      pageLeft: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      pageRight: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      leftSelectedIds: [],
      rightSelectedIds: []
    };
  },

  components: {
    GridLayout,
    HeadLayout,
  },

  computed: {
    tableOptionsLeft() {
      return {
        index: true,
        indexLabel: "序号",
        selection: true,
        menu: false,
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            prop: "extension",
            align: "center",
            width: 120,
          },
          {
            label: "上传时间",
            overHidden: true,
            prop: "updateTime",
            align: "center",
            width: 180,
          },
        ],
      };
    },
    tableOptionsRight() {
      return {
        index: true,
        indexLabel: "序号",
        selection: true,
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
        ],
      };
    },
    gridRowBtn() {
      return [
        {
          label: "取消置顶",
          emit: "row-cancel",
          type: "button",
          icon: "",
        },
      ];
    },
  },

  mounted() {
    this.onLoad(this.pageLeft);
    this.onLoadRight(this.pageRight);
  },
  activated(){
    console.log(333);
    this.onLoadRight(this.pageRight);
    this.onLoad(this.pageLeft);
  },
  methods: {
    onLoad(page) {
      this.pageLeft = page;
      this.crudLoadingLeft = true;
      listWithType(page.currentPage, page.pageSize, this.searchLeft).then(
        (res) => {
          const data = res.data.data;
          this.$refs.gridLayOutLeft.page.total = data.total;
          this.pageLeft.total = data.total;
          this.tableDataLeft = data.records;
          this.crudLoadingLeft = false;
        }
      );
    },
    search(page) {
      page.currentPage = 1
      this.onLoad(page)
    },
    onLoadRight(page) {
      this.pageRight = page;
      this.crudLoadingRight = true;
      pageList(page.currentPage, page.pageSize, this.searchRight).then(
        (res) => {
          const data = res.data.data;
          this.$refs.gridLayOutRight.page.total = data.total;
          this.pageRight.total = data.total;
          this.tableDataRight = data.records;
          this.crudLoadingRight = false;
        }
      );
    },
    searchR(page) {
      page.currentPage = 1
      this.onLoadRight(page)
    },
    rowCancel(row) {
      let params = {
        ids: row.id,
        isTrendingSearch: 0,
      };
      updateIsTrendingSearch(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "取消成功",
          });
          this.onLoadRight(this.pageRight);
          this.onLoad(this.pageLeft);
        }
      });
    },
    changeTrendingSearch(ids, status) {
      let params = {
        ids: ids.toString(),
        isTrendingSearch: status,
      };
      updateIsTrendingSearch(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.onLoadRight(this.pageRight);
          this.onLoad(this.pageLeft);
        }
      });
    },
    selectionLeftChange(list) {
      this.leftSelectedIds = [];
      list.forEach((ele) => {
        this.leftSelectedIds.push(ele.id);
      });
    },
    selectionRightChange(list) {
      this.rightSelectedIds = [];
      list.forEach((ele) => {
        this.rightSelectedIds.push(ele.id);
      });
    },
    checkedChange(type){
      if(type == 'left'){
        if (this.rightSelectedIds.length > 0) {
        this.changeTrendingSearch(this.rightSelectedIds, 0);
        }
      } else{
        if (this.leftSelectedIds.length > 0) {
        this.changeTrendingSearch(this.leftSelectedIds, 1);
       }
      }
    }
  },
};
</script>

<style scoped lang='scss'>
.content {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 46px);
  background: #ffffff;
  .content-item {
    //flex: 1;
    width: 47%;
    .head-search {
      padding: 12px;
      background: #ffffff;
    }
  }
  .tree-transfer-middle {
    display: flex;
    justify-content: center;
    width: 6%;
    align-items: center;
    background: #fff;
  }
  ::v-deep .el-table {
    height: calc(100vh - 320px) !important;
    max-height: calc(100vh - 320px) !important;
  }
}
</style>
